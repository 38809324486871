import { useContext, useState } from "react";
import {
  postCommentAccounting,
  postCompanyAccounting,
  postIntegrationFeedback,
} from "../../../../../api";
import {
  InfoModal,
  ManualModal,
  FileUploaderStateless,
  UploadButton,
  TextArea,
  FileList,
} from "../../../../../components";
import Input from "../../../../../components/input";
import AccountingSync from "../../../../../components/accountingSync";
import { SubmitButton } from "../submitButton";
import { SupportedAccounting } from "../supportedAccounting";
import { UploadAccountingInformation } from "../uploadAccountingInformation";
import { UserContext } from "../../../../../hooks/useWhoAmI";

export const AccountingModals = ({
  hide,
  step,
  setStep,
  files,
  setFile,
  uploadedFilesLength,
  onHandleRemove,
  uploadFilesAndComment,
  text,
  setText,
  fileUploadError,
  loading,
  setFileUploadError,
}) => {
  const { me } = useContext(UserContext);
  const [accountingSoftware, setAccountingSoftware] = useState("");

  const submitIntegrationFeedback = async (data) => {
    try {
      await postIntegrationFeedback(data);
    } catch (e) {
      console.error(e);
    }
  };
  const handleSubmit = async (e) => {
    await submitIntegrationFeedback({
      text: accountingSoftware,
      type: "accounting_integration",
      company: me.selected_company.id,
    });
    uploadFilesAndComment({
      e,
      fileEndpoint: postCompanyAccounting,
      commentEndpoint: postCommentAccounting,
    });
  };

  return (
    <>
      <ManualModal
        hide={hide}
        visible={step === 1}
        title="Manual upload of accounting data"
        leftContent={
          <div className="flex flex-col gap-3">
            <FileUploaderStateless
              files={files}
              setFile={setFile}
              uploadedFilesLength={uploadedFilesLength}
              onHandleRemove={onHandleRemove}
              error={fileUploadError}
              setFileUploadError={setFileUploadError}
            />
            <FileList files={files} onHandleRemove={onHandleRemove} />
            <div className={`${files.length > 0 && "border-t"} pt-4`}>
              <TextArea
                id="accountingComment"
                value={text}
                onChange={setText}
                label="Additional comments on your uploads"
                placeholder="Write your comments here..."
              />
              <Input
                className="mt-4"
                type="text"
                id="name"
                label="What accounting software do you use?"
                placeholder="Enter your accounting software"
                value={accountingSoftware}
                onChange={(e) => setAccountingSoftware(e.target.value)}
              />
            </div>
          </div>
        }
        rightContent={<UploadAccountingInformation />}
        submitButton={
          <SubmitButton
            loading={loading}
            files={files}
            onHandleSubmit={(e) => handleSubmit(e)}
          />
        }
      />
      <InfoModal
        hide={hide}
        visible={step === 2}
        title="Supported accounting software"
        content={<SupportedAccounting />}
        closeText="Close"
        submitButton={
          <AccountingSync
            company_monto_uuid={me.selected_company.monto_uuid}
            company_name={me.selected_company.name}
          />
        }
      />
      <InfoModal
        hide={hide}
        visible={step === 3}
        title="Manual upload of accounting data"
        closeText="Cancel"
        content={
          <div className="max-w-xl">
            <UploadAccountingInformation />
          </div>
        }
        submitButton={<UploadButton onClick={() => setStep(1)} />}
      />
    </>
  );
};
