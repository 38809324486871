import React from "react";
import Info from "../info";

const Input = ({
  id,
  label,
  placeholder,
  type,
  pattern,
  className,
  required = false,
  onChange,
  value,
  error,
  disabled,
  info,
  checked,
  onBlur,
}) => {
  let inputClassName =
    "focus:ring-float-purple-3 focus:ring-1 focus:border-float-purple-4 block w-full pl-2 pr-2 sm:text-sm border-gray-300 rounded h-10 disabled:text-black disabled:bg-zinc-100";
  if (error) {
    inputClassName += " border-red-300 focus:ring-red-300 focus:border-red-600";
  }

  if (type === "checkbox") {
    return (
      <div className="flex gap-3 items-center">
        <input
          type={type}
          name={id}
          id={id}
          required={required}
          onChange={onChange}
          value={value}
          disabled={disabled}
          checked={checked}
          onBlur={onBlur}
        />
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
          {info && <Info value={info} />}
        </label>
      </div>
    );
  }

  return (
    <div className={className}>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
        {info && <Info value={info} />}
      </label>
      <div className="mt-1 relative">
        <input
          type={type}
          name={id}
          id={id}
          className={inputClassName}
          placeholder={placeholder}
          pattern={pattern}
          required={required}
          onChange={onChange}
          value={value}
          disabled={disabled}
          onBlur={onBlur}
        />
        {error && <p className="text-xs text-functional-red-100">{error}</p>}
      </div>
    </div>
  );
};

export default Input;
