import { useContext } from "react";
import { CountryContext } from "../../hooks/useCountry";
import { UserContext } from "../../hooks/useWhoAmI";
import AccountingSync from "../accountingSync";
import Accordion from "../accordion";
import Loading from "../loading";
import { Icon } from "@iconify/react";
import { useAccountingFiles } from "../../hooks/useAccountingFiles";
import { FileList } from "../fileList";
import { UploadButton } from "../uploadButton";
import { DeleteFileModal } from "../../views/dashboard/onboard/components";
import {
  useGetAccountingConnections,
  useDeleteFileModal,
} from "../../views/dashboard/onboard/hooks";
import { AccountingConnectionList } from "../accountingConnectionList";

export const AccountingAccordion = ({
  uploadComplete,
  onUploadButtonClick,
  onSuportedAccountingClick,
  onDataClick,
  checked,
  open,
}) => {
  const { me } = useContext(UserContext);
  const { country } = useContext(CountryContext);
  const { accountingFiles, deleteAccountFileById, isLoading } =
    useAccountingFiles({ uploadComplete });
  const { accountingConnections, loadingAccountingConnections } =
    useGetAccountingConnections();
  const {
    showDeleteModal,
    openDeleteModal,
    closeDeleteModal,
    onDeleteFile,
    currentFile,
  } = useDeleteFileModal({ deleteFileById: deleteAccountFileById });

  const loadingContent = loadingAccountingConnections || isLoading;
  const hasFiles = accountingFiles && accountingFiles.length > 0;

  if (!me) {
    return <Loading />;
  }
  return (
    <>
      <Accordion
        title="Add financial information"
        checked={checked}
        open={open}
      >
        <div className="ml-[77px] mr-12">
          <p className={`mb-8`}>
            By connecting your account software you only share your balance
            sheet and income statement for the last 24 months. We will not have
            rights to make changes in your accounting software.
          </p>
          <div>
            {
              <div className="flex flex-col gap-4 md:flex-row md:gap-0 mb-8">
                {country.accounting_monto && (
                  <div className="flex flex-col gap-2 md:gap-4 items-center md:border-r-2 border-float-grey-10 md:pr-6">
                    <AccountingSync
                      company_monto_uuid={me.selected_company.monto_uuid}
                      company_name={me.selected_company.name}
                    />
                    <p
                      className="text-sm font-normal flex gap-2 items-center cursor-pointer text-float-purple-2"
                      onClick={onSuportedAccountingClick}
                    >
                      <Icon icon="ri:information-line" width={16} />
                      Supported accounting software
                    </p>
                  </div>
                )}
                <div
                  className={`flex flex-col gap-2 md:gap-4 items-center ${
                    country.accounting_monto && "md:pl-6"
                  }`}
                >
                  {country.accounting_upload && (
                    <>
                      <UploadButton onClick={onUploadButtonClick} />
                      <p
                        className="text-sm font-normal flex gap-2 items-center cursor-pointer text-float-purple-2"
                        onClick={onDataClick}
                      >
                        <Icon icon="ri:information-line" width={16} />
                        What data do we need?
                      </p>
                    </>
                  )}
                </div>
              </div>
            }
          </div>
          {loadingContent && (
            <div className="mb-5 flex flex-row gap-4 pt-3 items-center">
              <Loading height={24} width={24} />
              <p className="text-sm italic">Loading</p>
            </div>
          )}
        </div>
        {!loadingContent && (
          <div>
            <div>
              <AccountingConnectionList
                accountingList={accountingConnections}
              />
            </div>
            <div className={`${hasFiles ? "border-t" : ""}`}>
              <FileList
                files={accountingFiles}
                onHandleRemove={openDeleteModal}
                isLoading={isLoading}
              />
            </div>
          </div>
        )}
      </Accordion>
      <DeleteFileModal
        visible={showDeleteModal}
        hide={closeDeleteModal}
        file={currentFile}
        onDelete={onDeleteFile}
      />
    </>
  );
};
