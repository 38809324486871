export const ConfirmLoan = ({
  onComplete,
  account,
  amount,
  gracePeriod,
  term,
  activationDate,
  interest,
  currency,
  setVisible,
}) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col space-y-4">
        <div className="space-y-2">
          <p className="font-semibold">Loan Amount</p>
          <p className="text-2xl">
            {amount} {currency}
          </p>
        </div>
        <div className="space-y-2">
          <p className="font-semibold">Term</p>
          <p>{term} months</p>
        </div>
        <div className="space-y-2">
          <p className="font-semibold">Grace Period</p>
          <p>{gracePeriod === "0" ? "No" : `${gracePeriod} months`}</p>
        </div>
        <div className="space-y-2">
          <p className="font-semibold">Interest</p>
          <p>{interest} %</p>
        </div>
        <div className="space-y-2">
          <p className="font-semibold">Pay-out Date</p>
          <p>{activationDate.toISOString().split("T")[0]}</p>
        </div>
        <div className="space-y-2">
          <p className="font-semibold">Payout Account</p>
          <p>
            {account.alias ? <span>{account.alias}</span> : "Account"}
            {account.account_number && <span> {account.account_number}</span>}
            {account.iban && <span> / {account.iban}</span>}
            {account.currency && <span> ({account.currency})</span>}
          </p>
        </div>
      </div>
      <div className="flex flex-col space-y-4 mt-4">
        <div className="flex flex-row">
          <button
            className="bg-transparent text-float-purple-2 my-auto cursor-pointer border-transparent font-bold hover:border-transparent hover:bg-float-purple-5 ml-auto"
            onClick={() => setVisible(false)}
          >
            Back
          </button>
          <button className="ml-4" onClick={() => onComplete(account.id)}>
            Confirm Loan
          </button>
        </div>
        <p className="text-sm text-center text-gray-500">
          Please review all details before confirming your loan application.
        </p>
      </div>
    </div>
  );
};
