import { useContext, useState, useEffect } from "react";
import { useGetSubscriptionServices } from "./useGetSubscriptionServices";
import { UserContext } from "../../../../../hooks/useWhoAmI";
import {
  postIntegrationFeedback,
  patchIntegrationFeedback,
} from "../../../../../api";
import Input from "../../../../../components/input";
import useAPI from "../../../../../hooks/useAPI";

export const SupportedSubscriptions = () => {
  const { subscriptions } = useGetSubscriptionServices();
  const [subscriptionSoftware, setSubscriptionSoftware] = useState("");
  const [initial, setInitial] = useState(null);
  const { me } = useContext(UserContext);
  const [result] = useAPI({
    me,
    path: "v1/feedback/integration/",
  });

  useEffect(() => {
    const initial = result.find((r) => r.type === "subscription_integration");
    setSubscriptionSoftware(initial?.text || "");
    setInitial(initial);
  }, [result]);

  return (
    <div className="flex flex-col md:flex-row gap-6">
      <div className="md:w-1/3">
        <h5 className="font-bold">What data do we use?</h5>
        <p className="text-sm mt-1">
          We will read your subscription and billing data in order to calculate
          KPIs like your MRR, net revenue retention, churn rate and customer
          concentration. By sharing your Chargebee data it does not give us
          rights to edit or make changes.
        </p>
        <p className="text-sm mt-2">
          By sharing your data it helps us evaluate your credit score and give
          you a good offer.
        </p>
      </div>
      <div className="max-h-96 md:w-2/3 flex flex-col gap-5 mb-10">
        <div className="border rounded-xl p-7">
          {subscriptions.map(({ logo, name }) => (
            <img key={name} src={logo} alt={name} className="h-fit m-auto" />
          ))}
        </div>
        <p className="text-sm">
          We will keep adding more services. Manually upload your subscription
          KPIs instead if you don’t use Chargebee or if not all your data run
          through Chargbee. We will keep adding more services. By providing your
          current subscription services below, you help us with future
          integrations.
        </p>
        <Input
          className="mt-4"
          type="text"
          id="name"
          label="What subscription software do you use?"
          placeholder="Enter your subscription software"
          value={subscriptionSoftware}
          onChange={(e) => setSubscriptionSoftware(e.target.value)}
          onBlur={() => {
            if (initial) {
              patchIntegrationFeedback(initial.id, {
                text: subscriptionSoftware,
              });
            } else {
              postIntegrationFeedback({
                text: subscriptionSoftware,
                type: "subscription_integration",
                company: me.selected_company.id,
              });
            }
          }}
        />
      </div>
    </div>
  );
};
